import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')
const TheAdminContainer = () => import('@/containers/TheAdminContainer')

//Sections
const Login = () => import('@/pages/Login')
const NewAccountForm = () => import('@/pages/NewAccountForm')
const ActivationForm = () => import('@/pages/ActivationForm')
const ForgetForm = () => import('@/pages/ForgetForm')
const RecoveryForm = () => import('@/pages/RecoveryForm')
const Users = () => import('@/pages/Users')
const Customers = () => import('@/pages/Customers')
const Invoices = () => import('@/pages/Invoices')
const Invoice = () => import('@/pages/Invoice')
const CreditNotes = () => import('@/pages/CreditNotes')
const CreditNote = () => import('@/pages/CreditNote')
const Payments = () => import('@/pages/Payments')
const Payment = () => import('@/pages/Payment')
const CfdisImport = () => import('@/pages/CfdisImport')

//Admins
const Accounts = () => import('@/pages/administrator/Accounts')
const Account = () => import('@/pages/administrator/Account')

//Users
const MyAccount = () => import('@/pages/configuration/MyAccount')
const MySubscription = () => import('@/pages/configuration/MySubscription')
const MyPayments = () => import('@/pages/configuration/MyPayments')
const Fiscal = () => import('@/pages/configuration/Fiscal')
const Fiel = () => import('@/pages/configuration/Fiel')
const Certificates = () => import('@/pages/configuration/Certificates')
const Serials = () => import('@/pages/configuration/Serials')
const FiscalProducts = () => import('@/pages/configuration/FiscalProducts')
const Taxes = () => import('@/pages/configuration/Taxes')
const Currencies = () => import('@/pages/configuration/Currencies')
const Units = () => import('@/pages/configuration/Units')

const Suppliers = () => import('@/pages/Suppliers')
const Products = () => import('@/pages/Products')

// Views
const Home = () => import('@/views/Home')
const Dashboard = () => import('@/views/Dashboard')
const Onboarding = () => import('@/views/Onboarding')

const Colors = () => import('@/views/theme/Colors')
const Typography = () => import('@/views/theme/Typography')

const Charts = () => import('@/views/charts/Charts')
const Widgets = () => import('@/views/widgets/Widgets')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Switches = () => import('@/views/base/Switches')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tables = () => import('@/views/tables/Tables')
const AdvancedTables = () => import('@/views/tables/AdvancedTables')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')
const LoadingButtons = () => import('@/views/buttons/LoadingButtons')

// Views - Editors
const TextEditors = () => import('@/views/editors/TextEditors')
const CodeEditors = () => import('@/views/editors/CodeEditors')

// Views - Forms
const BasicForms = () => import('@/views/forms/Forms')
const AdvancedForms = () => import('@/views/forms/AdvancedForms')
const ValidationForms = () => import('@/views/forms/ValidationForms')
const MultiSelect = () => import('@/views/forms/MultiSelect')

// Views GoogleMaps
const GoogleMaps = () => import('@/views/GoogleMaps')

// Views - Icons
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
const Brands = () => import('@/views/icons/Brands')
const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')
const Toaster = () => import('@/views/notifications/Toaster')


// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login2 = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// Users
const User = () => import('@/views/users/User')

// Plugins
const Draggable = () => import('@/views/plugins/Draggable')
const Calendar = () => import('@/views/plugins/Calendar')
const Spinners = () => import('@/views/plugins/Spinners')

// Apps -> Invoice
//const Invoice = () => import('@/views/apps/invoicing/Invoice')

// Apps -> Email
const EmailApp = () => import('@/views/apps/email/EmailApp')
const Compose = () => import('@/views/apps/email/Compose')
const Inbox = () => import('@/views/apps/email/Inbox')
const Message = () => import('@/views/apps/email/Message')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Inicio',
      component: TheContainer,
      children: [
        {
          path: 'home',
          name: 'Inicio',
          component: Home
        },
        {
          path: 'welcome',
          name: 'Onboarding',
          component: Onboarding
        },
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: '/users',
          name: 'Usuarios',
          component: Users
        },
        {
          path: '/customers',
          name: 'Clientes',
          component: Customers
        },
        {
          path: '/suppliers',
          name: 'Proveedores',
          component: Suppliers
        },
        {
          path: '/currencies',
          name: 'Monedas',
          component: Currencies
        },
        {
          path: '/units',
          name: 'Unidades',
          component: Units
        },
        {
          path: '/products',
          name: 'Productos',
          component: Products
        },
        {
          path: '/invoices',
          name: 'Facturas',
          component: Invoices
        },
        {
          path: '/invoices/:id/:option',
          name: 'Ficha de Factura',
          component: Invoice
        },
        {
          path: '/invoices/draft',
          name: 'Nueva Factura',
          component: Invoice
        },
        {
          path: '/credit-notes',
          name: 'Notas de Crédito',
          component: CreditNotes
        },
        {
          path: '/credit-notes/draft',
          name: 'Nueva Nota de Crédito',
          component: CreditNote
        },
        {
          path: '/credit-notes/:id/view',
          name: 'Ficha de Nota de Crédito',
          component: CreditNote
        },
        {
          path: '/payments',
          name: 'Recepción de Pagos',
          component: Payments
        },
        {
          path: '/payments/draft',
          name: 'Nuevo Pago',
          component: Payment
        },
        {
          path: '/payments/:id/view',
          name: 'Ficha de Pago',
          component: Payment
        },
        {
          path: '/cfdis-import',
          name: 'Cfdi\'s Recibidos',
          component: CfdisImport
        },        
        {
          path: '/my-account',
          name: 'Mi Cuenta',
          component: MyAccount
        },
        {
          path: '/my-subscription',
          name: 'Mi Suscripción',
          component: MySubscription
        },        
        {
          path: '/my-payments',
          name: 'Mis Pagos',
          component: MyPayments
        },
        {
          path: '/fiscal',
          name: 'Información Fiscal',
          component: Fiscal
        },
        {
          path: '/fiel',
          name: 'Fiel (eFirma)',
          component: Fiel
        },
        {
          path: '/certificates',
          name: 'Certificados',
          component: Certificates
        },
        {
          path: '/serials',
          name: 'Series y Folios',
          component: Serials
        },
        {
          path: '/fiscal-products',
          name: 'Productos ( SAT )',
          component: FiscalProducts
        },
        {
          path: '/taxes',
          name: 'Impuestos',
          component: Taxes
        },
        {
          path: 'theme',
          redirect: '/theme/colors',
          name: 'Theme',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'colors',
              name: 'Colors',
              component: Colors
            },
            {
              path: 'typography',
              name: 'Typography',
              component: Typography
            }
          ]
        },
        {
          path: 'charts',
          name: 'Charts',
          component: Charts
        },
        {
          path: 'tables',
          redirect: '/tables/tables',
          name: 'Tables',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'tables',
              name: 'Basic tables',
              component: Tables
            },
            {
              path: 'advanced-tables',
              name: 'Advanced tables',
              component: AdvancedTables
            }
          ]
        },
        {
          path: 'widgets',
          name: 'Widgets',
          component: Widgets
        },
        {
          path: 'users',
          meta: { label: 'Users'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'Users',
              component: Users
            },
            {
              path: ':id',
              meta: {
                label: 'User Details'
              },
              name: 'User',
              component: User
            }
          ]
        },
        {
          path: 'base',
          redirect: '/base/cards',
          name: 'Base',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'breadcrumbs',
              name: 'Breadcrumbs',
              component: Breadcrumbs
            },
            {
              path: 'cards',
              name: 'Cards',
              component: Cards
            },
            {
              path: 'carousels',
              name: 'Carousels',
              component: Carousels
            },
            {
              path: 'collapses',
              name: 'Collapses',
              component: Collapses
            },
            {
              path: 'jumbotrons',
              name: 'Jumbotrons',
              component: Jumbotrons
            },
            {
              path: 'list-groups',
              name: 'List Groups',
              component: ListGroups
            },
            {
              path: 'navs',
              name: 'Navs',
              component: Navs
            },
            {
              path: 'navbars',
              name: 'Navbars',
              component: Navbars
            },
            {
              path: 'paginations',
              name: 'Paginations',
              component: Paginations
            },
            {
              path: 'popovers',
              name: 'Popovers',
              component: Popovers
            },
            {
              path: 'progress-bars',
              name: 'Progress Bars',
              component: ProgressBars
            },
            {
              path: 'switches',
              name: 'Switches',
              component: Switches
            },
            {
              path: 'tabs',
              name: 'Tabs',
              component: Tabs
            },
            {
              path: 'tooltips',
              name: 'Tooltips',
              component: Tooltips
            }
          ]
        },
        {
          path: 'buttons',
          redirect: '/buttons/standard-buttons',
          name: 'Buttons',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'standard-buttons',
              name: 'Standard Buttons',
              component: StandardButtons
            },
            {
              path: 'button-groups',
              name: 'Button Groups',
              component: ButtonGroups
            },
            {
              path: 'dropdowns',
              name: 'Dropdowns',
              component: Dropdowns
            },
            {
              path: 'brand-buttons',
              name: 'Brand Buttons',
              component: BrandButtons
            },
            {
              path: 'loading-buttons',
              name: 'Loading Buttons',
              component: LoadingButtons
            }
          ]
        },
        {
          path: 'editors',
          redirect: '/editors/text-editors',
          name: 'Editors',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'text-editors',
              name: 'Text Editors',
              component: TextEditors
            },
            {
              path: 'code-editors',
              name: 'Code Editors',
              component: CodeEditors
            }
          ]
        },
        {
          path: 'forms',
          redirect: '/forms/basic-forms',
          name: 'Forms',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'basic-forms',
              name: 'Basic Forms',
              component: BasicForms
            },
            {
              path: 'advanced-forms',
              name: 'Advanced Forms',
              component: AdvancedForms
            },
            {
              path: 'validation-forms',
              name: 'Form Validation',
              component: ValidationForms
            },
            {
              path: 'multi-select',
              name: 'Multiselect',
              component: MultiSelect
            },
          ]
        },
        {
          path: 'google-maps',
          name: 'Google Maps',
          component: GoogleMaps
        },
        {
          path: 'icons',
          redirect: '/icons/font-awesome',
          name: 'Icons',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'coreui-icons',
              name: 'CoreUI Icons',
              component: CoreUIIcons
            },
            {
              path: 'flags',
              name: 'Flags',
              component: Flags
            },
            {
              path: 'brands',
              name: 'Brands',
              component: Brands
            }
          ]
        },
        {
          path: 'notifications',
          redirect: '/notifications/alerts',
          name: 'Notifications',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'alerts',
              name: 'Alerts',
              component: Alerts
            },
            {
              path: 'badges',
              name: 'Badges',
              component: Badges
            },
            {
              path: 'modals',
              name: 'Modals',
              component: Modals
            },
            {
              path: 'toaster',
              name: 'Toaster',
              component: Toaster
            }
          ]
        },
        {
          path: 'plugins',
          redirect: '/plugins/draggable',
          name: 'Plugins',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'draggable',
              name: 'Draggable Cards',
              component: Draggable
            },
            {
              path: 'calendar',
              name: 'Calendar',
              component: Calendar
            },
            {
              path: 'spinners',
              name: 'Spinners',
              component: Spinners
            }
          ]
        },
       
        {
          path: 'apps',
          name: 'Apps',
          redirect: '/apps/invoicing/invoice',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'invoicing',
              redirect: '/apps/invoicing/invoice',
              name: 'Invoicing',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'invoice',
                  name: 'Invoice',
                  component: Invoice
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '/',
      redirect: '/accounts',
      name: 'Inicio',
      component: TheAdminContainer,
      children: [
        {
          path: 'accounts',
          name: 'Cuentas',
          component: Accounts
        },
        {
          path: 'accounts/:id/view',
          name: 'Ficha de Cuente',
          component: Account
        },
      ]
    },
    {
      path: '/login',
      name: 'Login',
      meta: {
        is_public: true,
      },
      component: Login
    },
    {
      path: '/signup',
      name: 'Registra tu Cuenta',
      meta: {
        is_public: true,
      },
      component: NewAccountForm
    },
    {
      path: '/account/active/:id/:code',
      name: 'Activa tu Cuenta',
      meta: {
        is_public: true,
      },
      component: ActivationForm
    },
    {
      path: '/forget',
      name: '¿Olvidaste tu Contraseña?',
      meta: {
        is_public: true,
      },
      component: ForgetForm
    },
    {
      path: '/account/recover-password/:id/:code',
      name: 'Recupera tu Contraseña',
      meta: {
        is_public: true,
      },
      component: RecoveryForm
    },
  ]
})