import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  asideShow: false,
  darkMode: false,
  toasts: [],
  isLoading: false,
  account: false,
  stamps: 100,
  deadline_days: 100,
  deadline_date: '06/02/2023',
  permissions: []
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  toggle (state, variable) {
    state[variable] = !state[variable]
  },
  get (state, variable) {
    return state[variable];
  },
  clear (state) {
    state.toasts = [];
  },
  setPermissions (state, permissions) {
    state.permissions = permissions;
  },
  hasPermission (permission) {
    if(state.permissions[permission] === undefined || state.permissions[permission]){
      return 1;
    }

    return 0;
  },
  setAccount (state, account) {
    state.account = account;
  },
  setStamps (state, stamps) {
    state.stamps = stamps;
  },
  setDeadlineDays (state, days) {
    state.deadline_days = days;
  },
  setDeadlineDate (state, date) {
    state.deadline_date = date;
  },
  hasAccess (state) {
    if(state.deadline_days <= 0 || state.stamps <= 0){
      return 0;
    }

    return 1;
  },
  toast (state, data) {
    if(data.color == "error"){
      data.color = "danger";
    }
    state.toasts.push({
      autohide: 3000,
      closeButton: true,
      fade: true,
      position: 'top-right',
      header: 'Notificación del Sistema',
      color: data.color,
      content: data.message
    })
  },
  loading (state) {
    state.isLoading = true;
  },
  loaded (state) {
    state.isLoading = false;
  }
}

export default new Vuex.Store({
  state,
  mutations
})