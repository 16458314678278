import axios from 'axios';

// ======================Axios configuration===============================

let baseUrl = "https://apifacturador.inwork.site";

if(document.domain == 'facturador.orderly.mx'){
    baseUrl = "https://apifacturador.orderly.mx";
}

if(document.domain == 'demo.facturador.site' || document.domain == 'localhost'){
    baseUrl = "http://api.facturador.site";
}

axios.defaults.baseURL = baseUrl;

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Accept'] = 'Application/json';

if (localStorage.getItem('token') === null || localStorage.getItem('token') === 'undefined') {
    axios.defaults.headers.common['access-token'] = '';
} else {
    axios.defaults.headers.common['access-token'] = localStorage.getItem('token');
}

axios.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
})

axios.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    if(error.response !== undefined){
        if (error.response.status === 401) {
            console.log(error.response);
        } else if (error.response.status === 500 || error.response.status === 404 || error.response.status === 400) {
            throw error;
        } else {
            return Promise.reject(error);
        }
    }
    else{
        return Promise.reject(error);
    }
})

// ======================Axios configuration===============================
